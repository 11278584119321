import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import logo from "../assets/checkit-white.png";
import { useNavigate } from "react-router";
import { checkoutRedirect } from "../utils/api/api-consumer";
import { TbJacket } from "react-icons/tb";

const Container = styled.div`
  height: 100vh;
  background-color: #184730;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Jost;
`;

const SuccessTitle = styled.div`
  font-size: 40px;
  margin-bottom: 20px;
`;

const SuccessSubHeading = styled.div`
  width: 70%;
  font-size: 14px;
  color: white;
  font-family: Jost;
`;

const FailMessage = styled.div`
  font-size: 14px;
  color: white;
  font-family: Jost;
  width: 70%;
`;

const Logo = styled.img`
  width: 50%;
  max-width: 200px; /* Set a maximum width for the logo */
  margin-bottom: 120px;
  margin-top: 70px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const Button = styled.button`
  padding: 10px 24px 7px;
  border-radius: 10px;
  background-color: white;
  color: #184730;
  max-width: 200px;
  font-family: Jost;
  cursor: pointer;
  border: none;
  font-size: 18px;
  display: flex;
`;

const Footer = styled.div`
  color: #fff;
  padding: 0px;
  text-align: center;
  position: fixed;
  bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const FooterItems = styled.div`
  width: 100%;
  font-family: Jost;
  font-size: 10px;
`;

const FooterSpan = styled.span`
  cursor: pointer;
  color: #ccc;
`;

const ArrowContainer = styled.div`
  padding-right: 20px;
  padding-top: 3px;
`;

const RequestCoat = () => {
  const [message, setMessage] = useState("");
  const [isPaid, setIsPaid] = useState(false);
  const [notPaidSuccess, setNotPaidSuccess] = useState(false);

  const navigate = useNavigate();

  const query = useMemo(() => new URLSearchParams(window.location.search), []);
  const success = useMemo(() => query.get("success"), [query]);
  const cancelled = useMemo(() => query.get("cancelled"), [query]);

  useEffect(() => {
    const id = query.get("id");
    if (!id || id === "null") {
      setMessage(
        "Something went wrong... Please try navigating from the provided link again."
      );
    }

    setIsPaid(false);
  }, [query]);

  const handleCheckoutRedirect = async (hangerId) => {
    const status = await checkoutRedirect(hangerId);

    if (status === 200) {
      setNotPaidSuccess(true);
    } else {
      navigate(`/request/?id=${query.get("id")}&cancelled=true`);
    }
  };

  return (
    <Container>
      <Logo src={logo} alt="Logo" />

      {success || notPaidSuccess ? (
        <SuccessContainer>
          <SuccessTitle>Requested!</SuccessTitle>
          <SuccessSubHeading>
            Please allow a few minutes for your coat to be prepared, then
            proceed to the coat check.
          </SuccessSubHeading>
        </SuccessContainer>
      ) : (
        <>
          <ButtonContainer>
            {isPaid ? (
              <form
                action={`https://checkit-backend-3f30ec23aff2.herokuapp.com/create-checkout-session/${query.get(
                  "id"
                )}`}
                method="POST"
              >
                <Button
                  disabled={!query.get("id") || query.get("id") === "null"}
                >
                  Request my coat
                </Button>
              </form>
            ) : (
              <Button
                disabled={!query.get("id") || query.get("id") === "null"}
                onClick={() => handleCheckoutRedirect(query.get("id"))}
              >
                <ArrowContainer>
                  <TbJacket />
                </ArrowContainer>
                Request Coat
              </Button>
            )}
          </ButtonContainer>
          {cancelled || message ? (
            <ButtonContainer>
              <FailMessage>
                {message.length
                  ? message
                  : "Something went wrong... Please try again"}
              </FailMessage>
            </ButtonContainer>
          ) : null}
        </>
      )}

      <Footer>
        <FooterItems>
          By submitting, I agree to CheckIts's{" "}
          <FooterSpan onClick={() => navigate("/termsofuse")}>
            Terms of Use
          </FooterSpan>{" "}
          and{" "}
          <FooterSpan onClick={() => navigate("/privacypolicy")}>
            Privacy Policy
          </FooterSpan>
        </FooterItems>
      </Footer>
    </Container>
  );
};

export default RequestCoat;
