import axios from "axios";

const BASE_URL = "https://8p01ujkad8.execute-api.us-east-2.amazonaws.com";

const StatusEnum = {
  coatChecked: "CoatChecked",
  pendingPickup: "PendingPickup",
};

const phoneNumberFormatter = (phoneNum) => {
  return phoneNum.trim().replace(/[^0-9]/g, "");
};

/*
  Params Structure
  {
    hangerId: string; - Ex: PT-12345
    phoneNum: string;
    coatStatus: StatusEnum
  }
*/
const putRequestBody = (params) => {
  return {
    hangerId: params.hangerId,
    phoneNum: phoneNumberFormatter(params.phoneNumber),
    coatStatus: StatusEnum[params.status],
  };
};

const patchRequestBody = (params) => {
  return {
    hangerId: params.hangerId,
    coatStatus: StatusEnum[params.status],
  };
};

export const createNewSession = async (params) => {
  try {
    const response = await axios.put(`${BASE_URL}/sessions`, {
      ...putRequestBody(params),
    });

    if (response.status === 200) {
      return "success";
    } else {
      return "failed";
    }
  } catch (error) {
    console.log(error);

    return "failed";
  }
};

export const updateSession = async (params) => {
  try {
    const response = await axios.patch(`${BASE_URL}/sessions`, {
      ...patchRequestBody(params),
    });

    return response.data;
  } catch (error) {
    console.log(error);

    return error.response;
  }
};

/*
    Params structure
    {
        barId: string;
        password: string;
    }
*/
export const authenticateBar = async (params) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/bars/${params.barId}`);

    if (data.password === params.password) {
      return {
        status: "success",
        barId: data.barId,
      };
    } else {
      return {
        status: "failed",
        message: "The Username/Password entered don't match our records.",
      };
    }
  } catch (error) {
    return {
      status: "failed",
      message: "Something went wrong. Please try again.",
    };
  }
};

export const deleteSession = async (hid) => {
  try {
    const response = await axios.delete(`${BASE_URL}/sessions/${hid}`);
    console.log(response);

    if (response.status !== 200) {
      alert("Something went wrong... Please try again");
      return "failed";
    }

    return "success";
  } catch (error) {
    return "failed";
  }
};

export const checkoutRedirect = async (hid) => {
  try {
    const res = await axios.patch(`${BASE_URL}/sessions`, {
      hangerId: hid,
      coatStatus: StatusEnum.pendingPickup,
    });

    return res.status;
  } catch (error) {
    return;
  }
};

export const getSessionsWithID = async (barId) => {
  try {
    const res = await axios.get(`${BASE_URL}/sessions`);

    // revisit this bc I don't know whether .body.data is the array of elements
    return res.data.filter(
      (session) => session.hangerId.split("-")[0] === barId
    );
  } catch (err) {
    console.log(err);
  }
};
