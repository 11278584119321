// CoatsModal.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  AiFillCheckCircle,
  AiOutlineCloseSquare,
  AiOutlineCheckSquare,
  AiFillDelete,
} from "react-icons/ai";
import { getSessionsWithID } from "../utils/api/api-consumer";
import PulseLoader from "react-spinners/PulseLoader";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 30px 20px;
  width: 40%;
  min-height: 70vh;
  max-height: 70vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const SearchBar = styled.input`
  width: 50%;
  padding: 8px 12px;
  border-radius: 6px;
  margin-bottom: 20px;
  font-family: Jost;
  border: none;
`;

const ListItem = styled.div`
  background: #fff;
  color: #184730;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 14px;
  width: 80%;
  position: relative;
`;

const CheckWrapper = styled.div`
  position: absolute;
  left: 10px;
  margin-top: 3px;
`;

const ConfirmWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 8px;
  top: 10.5px;
`;

const IconWrapper = styled.div`
  padding: 3px 1px;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #e1e1e1;
    transition: ease-in-out 0.2s;
    border-radius: 10%;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  color: #184730;
  position: absolute;
  right: 8px;
  top: 10.5px;
`;

const LoadingContainer = styled.div`
  height: 100%;
  margin-top: 40%;
`;

const AllCoatsModal = ({ isOpen, onClose, barId }) => {
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Placeholder data fetch
  useEffect(() => {
    // Replace this with your API call
    // For now, using placeholder data
    if (isOpen) {
      setIsLoading(true);
      (async function () {
        try {
          const response = await getSessionsWithID(barId);
          console.log(response);
          setItems(response);
        } catch (e) {
          console.error(e);
        }
      })();
      setIsLoading(false);
    }
  }, [isOpen, barId]);

  const handleOverlayClick = (e) => {
    // Check if the click target is outside of the ModalContent
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleConfirmDeleteClick = async (action, item) => {
    if (action === "delete") {
      setItems(
        items.length
          ? items.filter((elem) => elem.hangerId !== item.hangerId)
          : []
      );
    }

    setConfirmOpen((prevState) => ({
      ...prevState,
      [item.hangerId]: false, // Close the dropdown for the specific element
    }));
  };

  const handleDeleteClick = (item) => {
    // Toggle dropdown visibility for the specific element
    setConfirmOpen((prevState) => ({
      ...prevState,
      [item.hangerId]: !prevState[item.hangerId],
    }));
  };

  return (
    <>
      {isOpen && (
        <ModalOverlay onClick={handleOverlayClick}>
          <ModalContent>
            <SearchBar
              type="text"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {isLoading ? (
              <LoadingContainer>
                <PulseLoader size={12} color={"white"} />
              </LoadingContainer>
            ) : items.length ? (
              items
                .filter((item) =>
                  item.hangerId.toLowerCase().includes(search.toLowerCase())
                )
                .map((item, index) => (
                  <>
                    <ListItem key={index}>
                      {item.coatStatus === "PendingPickup" ? (
                        <CheckWrapper>
                          <AiFillCheckCircle />
                        </CheckWrapper>
                      ) : null}
                      {`${item.hangerId.split("-")[0]}-${
                        item.hangerId.split("-")[1]
                      } | ${item.hangerId.slice(-4)}`}
                      {confirmOpen[item.hangerId] ? (
                        <ConfirmWrapper>
                          <IconWrapper
                            onClick={() =>
                              handleConfirmDeleteClick("cancel", item)
                            }
                          >
                            <AiOutlineCloseSquare />
                          </IconWrapper>
                          <IconWrapper
                            onClick={() =>
                              handleConfirmDeleteClick("delete", item)
                            }
                          >
                            <AiOutlineCheckSquare />
                          </IconWrapper>
                        </ConfirmWrapper>
                      ) : (
                        <IconContainer>
                          <IconWrapper onClick={() => handleDeleteClick(item)}>
                            <AiFillDelete />
                          </IconWrapper>
                        </IconContainer>
                      )}
                    </ListItem>
                  </>
                ))
            ) : null}
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default AllCoatsModal;
