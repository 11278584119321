import "./App.css";
import SubmitPhoneNumber from "./components/SubmitPhoneNumber";
import Login from "./components/Login";
import CoatCheckDisplay from "./components/CoatCheckDisplay";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RequestCoat from "./components/RequestCoat";
import TermsOfUse from "./components/TermsOfUse";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Website from "./website/Website";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<SubmitPhoneNumber />} />
          <Route path="/login" element={<Login />} />
          <Route path="/bars" element={<CoatCheckDisplay />} />
          <Route path="/request" element={<RequestCoat />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/info" element={<Website />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
